import AlertService from "@/components/alerts/AlertService";
import TimeUtils from "@/components/TimeUtils";
import store from "@/store";
import { mergeAlertInstances } from "@/store/MergeAlerts";

export default {
  namespaced: true,
  name: "alerts",
  state: {
    activeAlertInstances: null,
    bufferedAlertInstances: null,
    isBuffering: false,
    autoIgnoreTimer: null,
  },
  getters: {
    activeAlertInstances(state) {
      return state.activeAlertInstances;
    },
    isBuffering(state) {
      return state.isBuffering;
    },
  },
  mutations: {
    setActiveAlertInstances(state, alerts) {
      state.activeAlertInstances = alerts;
    },
    setBufferedAlertInstances(state, alerts) {
      state.bufferedAlertInstances = alerts;
    },
    setIsBuffering(state, isBuffering) {
      state.isBuffering = isBuffering;
    },

    setAutoIgnoreTimer(state, timerId) {
      state.autoIgnoreTimer = timerId;
    },
  },
  actions: {
    fetchAlertInstances({ state, commit, dispatch }) {
      const factory = store.getters["navigation/activeFactory"];
      const isPresenter = store.getters["user/isPresenter"];
      const productionUnits = store.getters["navigation/activeFactoryProductionUnits"];
      const ignoreStartTime = isPresenter ? new Date() : null;
      if (factory?.id && productionUnits) {
        const puNameAssociation = productionUnits.reduce((acc, pu) => {
          acc[pu.id] = pu.name;
          return acc;
        }, {});
        AlertService.fetchInAppAlertInstances(factory.id).then((httpResponse) => {
          const alertInstances = httpResponse.data;
          if (alertInstances?.length > 0) {
            const flattenedAlertInstances = alertInstances.map((alert) => {
              return {
                productionUnitId: alert.production_unit_id,
                id: alert.id,
                raisedTime: alert.raised_time,
                dateTime: TimeUtils.dateTextRelativeToToday(alert.raised_time, factory.timezone),
                alertId: alert.alert_id,
                title: alert.alert_title,
                secondaryTitle: puNameAssociation[alert.production_unit_id],
                topRightText: TimeUtils.dateTextRelativeToToday(alert.raised_time, factory.timezone),
                productionUnitName: puNameAssociation[alert.production_unit_id],
                ignoreStartTime: ignoreStartTime,
                actionMessage: alert.action_message,
                actionUrl: alert.action_url,
              };
            });
            const mergedAlerts = mergeAlertInstances(state.activeAlertInstances, flattenedAlertInstances);
            if (state.isBuffering) {
              commit("setBufferedAlertInstances", mergedAlerts);
            } else {
              commit("setActiveAlertInstances", mergedAlerts);
            }
          }
          if (isPresenter) {
            dispatch("beginCountDownToDisappear");
          }
        });
      }
    },
    setBufferingState({ state, commit, newBufferState }) {
      const previousBufferState = state.isBuffering;
      commit("setIsBuffering", newBufferState);
      const bufferedInstances = state.bufferedAlertInstances;
      // If the previous state was set to buffer and there buffered instances -> set them to active
      if (previousBufferState && !newBufferState && bufferedInstances && bufferedInstances > 0) {
        commit("setActiveAlertInstances", bufferedInstances);
      }
    },
    handleAlertDeletion({ dispatch }, { data }) {
      dispatch("removeAlertInstanceWithAlertId", { alertId: data.alert_id });
    },
    handleAlertInstanceCompletion({ dispatch }, { data }) {
      dispatch("removeAlertInstance", { id: data.alert_instance_id });
    },
    removeAlertInstance({ commit, state }, { id }) {
      const currentAlertInstances = state.activeAlertInstances;

      // Create a new Map so Vue sees the reference change
      const updatedMap = new Map(currentAlertInstances);

      for (const [key, items] of updatedMap) {
        const filteredItems = items.filter((item) => item.id !== id);

        if (filteredItems.length > 0 && filteredItems.length !== items.length) {
          updatedMap.set(key, filteredItems);
        } else if (filteredItems.length === 0) {
          updatedMap.delete(key);
        }
      }

      commit("setActiveAlertInstances", updatedMap);
    },
    removeAlertInstanceWithAlertId({ commit, state }, { alertId }) {
      const currentAlertInstances = state.activeAlertInstances;

      // Create a new Map so Vue sees the reference change
      const updatedMap = new Map(currentAlertInstances);
      for (const [key] of updatedMap) {
        // alert id is part of the compound key
        if (key.includes(alertId)) {
          updatedMap.delete(key);
        }
      }
      commit("setActiveAlertInstances", updatedMap);
    },
    ignoreAlertInstance({ dispatch }, { alertPayload }) {
      dispatch("removeAlertInstance", { id: alertPayload.id });
      AlertService.ignoreAlertInstance(alertPayload.productionUnitId, alertPayload.id).catch((e) => console.warn(e));
    },
    completeAlertInstance({ dispatch }, { alertPayload }) {
      dispatch("removeAlertInstance", { id: alertPayload.id });
      AlertService.completeAlertInstance(alertPayload.productionUnitId, alertPayload.id).catch((e) => console.warn(e));
    },
    ignoreAllAlertInstances({ commit }) {
      commit("setActiveAlertInstances", new Map());
      const factoryId = store.getters["navigation/activeFactory"]?.id;
      if (factoryId) {
        AlertService.ignoreAllAlertInstances(factoryId).catch((e) => console.warn(e));
      }
    },

    // ──────────────────────────────────────────────────────────
    //  AUTOMATICALLY IGNORE ALERTS AFTER 60 SECONDS
    // ──────────────────────────────────────────────────────────

    startAutoIgnore({ state, commit, dispatch }) {
      if (state.autoIgnoreTimer) {
        clearInterval(state.autoIgnoreTimer);
      }

      const INTERVAL_MS = 1000 * 15; // Check every 15 seconds
      const timerId = setInterval(() => {
        const currentActiveAlertInstances = state.activeAlertInstances;
        if (!currentActiveAlertInstances || currentActiveAlertInstances.size === 0) {
          return;
        }
        const now = Date.now();
        const foundInstances = [];
        for (const [, items] of currentActiveAlertInstances) {
          items.forEach((item) => {
            const ignoreStart = item.ignoreStartTime;
            if (ignoreStart) {
              const diffSeconds = (now - ignoreStart) / 1000;
              if (diffSeconds >= 60) {
                foundInstances.push(item);
              }
            }
          });
        }
        // remove all first
        foundInstances.forEach((instance) => {
          dispatch("removeAlertInstance", { id: instance.id });
        });
        foundInstances.forEach((instance) => {
          AlertService.ignoreAlertInstance(instance.productionUnitId, instance.id).catch((e) => console.warn(e));
        });
      }, INTERVAL_MS);

      // Store our interval ID so we can clean up later
      commit("setAutoIgnoreTimer", timerId);
    },

    stopAutoIgnore({ state, commit }) {
      if (state.autoIgnoreTimer) {
        clearInterval(state.autoIgnoreTimer);
        commit("setAutoIgnoreTimer", null);
      }
    },
  },
};
