import { DateTime, Duration } from "luxon";
import i18n from "@/i18n";
import { DATE_TIME_FORMAT_NO_SECONDS, TIME_FORMAT_HH_MM } from "@/store/TimeUtils";

export const DATE_FORMAT = "yyyy-LL-dd";
export const ONE_HOUR_IN_MILLIS = 3600000;
export const ONE_MINUTE_IN_MILLIS = 60000;

class TimeUtils {
  secondsToHHMM(seconds) {
    if (seconds) {
      const duration = Duration.fromMillis(seconds * 1000);
      return duration.toFormat("hh:mm");
    } else {
      return "";
    }
  }

  durationText(durationMillis) {
    const h = i18n.t("common.h");
    const m = i18n.t("common.m");
    if (durationMillis === null || durationMillis === undefined) {
      let duration = Duration.fromMillis(0);
      return duration.toFormat(`hh'${h}'mm'${m}'`);
    }
    let duration = Duration.fromMillis(durationMillis);
    let hours = durationMillis / ONE_HOUR_IN_MILLIS;
    if (hours >= 10) {
      return duration.toFormat(`hh'${h}'mm'${m}'`);
    }
    if (hours >= 1) {
      return duration.toFormat(`h'${h}'mm'${m}'`);
    }
    let minutes = (durationMillis % ONE_HOUR_IN_MILLIS) / ONE_MINUTE_IN_MILLIS;
    if (minutes >= 10) {
      return duration.toFormat(`mm'${m}'`);
    } else {
      return duration.toFormat(`m'${m}'`);
    }
  }

  dateTextRelativeToToday(epochMs, zoneId) {
    const dateTime = DateTime.fromMillis(epochMs, { zone: zoneId });
    const now = DateTime.now().setZone(zoneId);
    if (dateTime.hasSame(now, "day")) {
      // Return only the time in the desired format
      return dateTime.toFormat(TIME_FORMAT_HH_MM);
    } else {
      // Return the full date and time in the desired format
      return dateTime.toFormat(DATE_TIME_FORMAT_NO_SECONDS);
    }
  }

  hhmmToSeconds(hhmm) {
    if (hhmm) {
      const dateTime = DateTime.fromFormat(hhmm, "hh:mm");
      return dateTime.hour * 3600 + dateTime.minute * 60;
    } else {
      return null;
    }
  }

  toEpochMillis(yearMonthDay, timezone) {
    const stringToDate = DateTime.fromFormat(yearMonthDay, DATE_FORMAT);
    const year = stringToDate.year;
    const month = stringToDate.month;
    const dayOfMonth = stringToDate.day;
    const dateUTC = DateTime.now()
      .setZone(timezone)
      .set({ year: year, month: month, day: dayOfMonth, hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toUTC();
    return dateUTC.toISO();
  }
}

export default new TimeUtils();
